import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Callout } from "../components/Callout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Callout mdxType="Callout">
  Don't miss the next GraphQL Summit, on October 8-10, 2024 in New York City.
  [**Book your
  tickets**](https://summit.graphql.com?utm_campaign=2024-04-09_graphqlsummitpromo&utm_medium=website&utm_source=odyssey),
  early bird prices end on May 20th.
    </Callout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      