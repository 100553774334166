import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Callout } from "../components/Callout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Callout mdxType="Callout">
  **Check your plan:** Part of this course covers the self-hosted GraphOS
  Router, which requires a GraphOS Enterprise plan. You can still follow along
  if your organization is on a different plan, but you won't be able to complete
  certain hands-on tasks. You can also test out this functionality by [signing
  up for a free Enterprise
  trial](https://studio.apollographql.com/signup?type=enterprise-trial&referrer=odyssey-ent).
    </Callout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      